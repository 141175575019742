import { render, staticRenderFns } from "./ReceivableItemEdit.vue?vue&type=template&id=2f473266&"
import script from "./ReceivableItemEdit.vue?vue&type=script&lang=js&"
export * from "./ReceivableItemEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports